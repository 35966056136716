import { css } from "styled-components";
import { mobileWidth, tabletWidth } from "./sizes";

export const mobile = (inner) => css`
  @media (max-width: ${mobileWidth}px) {
    ${inner};
  }
`;

export const tablet = (inner) => css`
  @media (max-width: ${tabletWidth}px) {
    ${inner};
  }
`;

export const customMedia = (maxWidth, inner) => css`
  @media (max-width: ${maxWidth}px) {
    ${inner};
  }
`;
