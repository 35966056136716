import React, { useState, useRef, useEffect, RefObject } from "react";
import styled, {css} from "styled-components/macro";
import exclamationMarkIcon from "../assets/icons/exclamationMark.svg";
import { mobile } from "./css/media";
import { closeOnBlur } from "./closeOnBlur";
import {useIsMobile} from "./usIsMobile";

export const HostPopupContainer = styled.div`
  position: relative;
  display: inline-block;
`;
export const HostPopupText = styled.div<{
  // width?: number;
  popupTextStyle?: any;
}>`
  width: ${({ popupTextStyle }) => popupTextStyle.width}px;
  height: auto;
  text-align: flex-start;
  padding: 16px;
  border-radius: 6px;
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: 20px;
  background-color: #ffff;
  box-shadow: 0px 4px 7px rgba(198, 198, 198, 0.21);
  top: ${({ popupTextStyle }) => popupTextStyle.top}px;
  right: ${({ popupTextStyle }) => popupTextStyle.right}px;
`;

export const HostExclamtionMark = styled.div``;
export const PopupText = styled.div`
  opacity: 54%;
`;
export const ExclamationMarkIcon = styled.img`
  display: flex;
  text-align: center;
  padding-inline-end: 10px;
  margin-inline-start: -30px;
  ${mobile(css`
      margin-inline-start: 0px;
  `)};
`;
const MicroCopyHostPopup = ({ text, popupTextStyle }) => {
  const [isHover, setIsHover] = useState(false);
  const wrapperRef: RefObject<any> = useRef(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    closeOnBlur(wrapperRef, setIsHover);
  }, []);


  return (
    <HostPopupContainer>
      <HostExclamtionMark
        ref={wrapperRef}
        onMouseEnter={() => !isMobile && setIsHover(true)}
        onMouseLeave={() => !isMobile && setIsHover(false)}
        onClick={() => isMobile && setIsHover(true)}
      >
        <ExclamationMarkIcon src={exclamationMarkIcon} />
      </HostExclamtionMark>
      {isHover && (
        <HostPopupText popupTextStyle={popupTextStyle}>
          <PopupText>{text}</PopupText>
        </HostPopupText>
      )}
    </HostPopupContainer>
  );
};

export default MicroCopyHostPopup;
