export const sidebarWidth = 182;
export const smallSideBarWidth = 70;
export const headerHeight = 100;
export const cornerHeight = 20;
export const footerHeight = 60;

export const filtersHeight = 86;
export const filtersToggleHeight = 115;
export const filtersToggleWidth = 160;

export const inputBorderRadius = 6;
export const selectBorderRadius = 20;
export const selectHeaderWidth = 11;
export const selectHeaderHeight = 3.8;

export const selectedItemsHeight = 89;

export const circleSize = 58;
export const circleSizeSmall = 40;
export const circleMarginBottom = 30;
export const circleMarginBottomSmall = 20;

export const mobileWidth = 768;
export const tabletWidth = 1023;

export const guestSearchMapToggleHeight = 50;
export const guestSearchMapToggleWidth = 65;

export const mobileFiltersWidth = 322;
export const mobileFiltersHeight = 508;
export const mobileFiltersHeaderHeight = 58;
export const mobileFiltersDividerHeight = 8;

export const mobileHeaderHeight = 88;
export const mobileFooterHeight = 188;

export const numOfStepsCreateSalon = 6;