import useWindowSize from "../components/common/useWindowSize";
import { mobileWidth, tabletWidth } from "./css/sizes";

const useIsMobile = () => {
  const [width] = useWindowSize();
  return width <= mobileWidth;
};

const useIsTablet = () => {
  const [width] = useWindowSize();
  return width > mobileWidth && width <= tabletWidth;
};

export { useIsMobile, useIsTablet };
